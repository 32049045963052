/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-25 17:41:58
 * @Module: 直播数据列表
 */
 <template>
  <div>
    <div class="header">
      <h6>直播数据</h6>
      <div class="btn-wrap">
        <p class="btn"
           @click="exportClick">
          <i class="icon iconfont icon-daochu btn"></i>
          <span>导出</span>
        </p>
      </div>
    </div>
    <el-card style="margin-top:20px">
      <el-row :gutter="20">
        <el-form :inline="true"
                 :size="'small'"
                 :model="formInline"
                 class="demo-form-inline">
          <el-col :span="6">
            <el-form-item label="探探号:">
              <el-input v-model="formInline.tick_account"
                        placeholder="请输入要查询的探探号"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="探探ID:">
              <el-input v-model="formInline.tick_number" placeholder="请输入要查询的探探ID"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="6"
                  v-role="['administrator']">
            <el-form-item label="归属人:">
              <belonger @belongerSeChange="belongerSeChange"></belonger>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="经纪人:">
              <brokerSelect @selectTreeChange="selectTreeChange"
                            @clearBrokerSelect="clearBrokerSelect"
                            placeholder="请选择经纪人"></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="筛选时间:">
              <el-date-picker v-model="formInline.broadcast_date"
                              type="daterange"
                              align="right"
                              unlink-panels
                              range-separator="~"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :picker-options="pickerOptions"
                              value-format="yyyy-MM-dd"
                              :default-value="datePickerDefaultValue"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary"
                         icon="el-icon-search"
                         @click="req_list(true)">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="alert-style">
        <el-alert title="未对公收益不参与平台结算。"
                  type="warning"
                  show-icon></el-alert>
      </div>
      <tablePage :tableData="tableData"
                 :currentPage="formInline.page"
                 :height="tableHeight"
                 @current-change="currentChange"
                 v-loading="loading"
                 :total="total"
                 id="table"
                 ref="tableList">
        <el-table-column label="主播信息"
                         min-width="300">
          <template slot-scope="scope">
            <div class="base-wrap">
              <el-avatar :size="64"
                         :src="scope.row.avatar"></el-avatar>
              <div>
                <p>{{ scope.row.nick_name }}</p>
                <p>探探号：{{ scope.row.tick_account }}</p>
                <p>探探ID：{{ scope.row.tick_id }}</p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="主播/经纪人（姓名）"
                         min-width="180">
          <template slot-scope="scope">
            <p>主播：{{ scope.row.name }}</p>
            <p>经纪人：{{ scope.row.broker_name }}</p>
          </template>
        </el-table-column>

        <el-table-column label="有效天/有效时长"
                         min-width="180">
          <template slot-scope="scope">
            <p>有效天：{{ scope.row.day }}天</p>
            <p>时长：{{scope.row.hour}}分钟</p>
          </template>
        </el-table-column>

        <el-table-column label="直播礼物收入"
                         min-width="180">
          <template slot-scope="scope">
            <p style="color:#D74D38;">总数：{{scope.row.broadcast}}</p>
            <p style="color:#60C3F7;">已对公：{{scope.row.public_broadcast}}</p>
            <p style="color:#F8B800;">未对公：{{scope.row.private_broadcast}}</p>
          </template>
        </el-table-column>

        <el-table-column prop="anchor_wallet"
                         min-width="100"
                         label="主播本月收益余额"></el-table-column>
        <el-table-column label="操作"
                         min-width="130">
          <template slot-scope="scope">
            <router-link :to="'anchorList/anchorDetail?anchor_id='+scope.row.anchor_id">
              <el-button type="text">主播详情</el-button>
            </router-link>
          </template>
        </el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>
 <script>
//  document.body.clientHeight
import tablePage from "components/tablePage";
import brokerSelect from "components/brokerSelect";
import belonger from "components/belonger";
import tableHeight from "mixins/tableHeight";
import api from "@/api";
export default {
  data () {
    return {
      loading: true,
      formInline: {
        broadcast_date: null, // 筛选时间
        tick_account: null, //探探号
        tick_number: null, //探探id
        manager: null, //归属人
        broker: null, // 经纪人
        page: 1,
      },
      tableData: [],
      total: 10,
      tableHeight: 1000,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      datePickerDefaultValue: [
        this.$timeFormat(
          new Date().setTime(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          ),
          "yyyy-mm-dd"
        ),
        this.$timeFormat(new Date().getTime(), "yyyy-mm-dd"),
      ],
    };
  },
  components: {
    tablePage,
    brokerSelect,
    belonger,
  },
  mixins: [tableHeight],
  created () {
    this.req_list(true);
  },
  mounted () {
    setTimeout(() => {
      this.tableHeight =
        document.body.clientHeight -
        document.querySelector("#table").offsetTop -
        72 -
        61;
    }, 20);
  },
  methods: {
    exportClick () {
      this.$excelDownload(this.formInline, "api/data/export");
    },
    async _initState (params = {}) {
      this.loading = true;
      const { status_code, meta, message } = await api.liveDataLsitApi(params);
      if (status_code === 200) {
        const {
          pagination: { total },
        } = meta;
        this.total = total;
        this.tableData = message;
        this.loading = false;
      } else if (status_code == 422) {
        Object.keys(message).forEach((item) => {
          this.$message.error({ message: message[item] });
        });
      }
    },
    currentChange (page) {
      this.formInline.page = page;
      this.req_list();
    },
    // 选择归属人回调
    belongerSeChange (id) {
      this.formInline.manager = id;
    },
    // 选择经纪人回调
    selectTreeChange (id) {
      this.formInline.broker = id;
    },
    // 清除选择经纪人回调
    clearBrokerSelect () {
      this.formInline.broker = null;
    },
    // 请求列表
    req_list (refresh) {
      if (refresh) this.formInline.page = 1;
      if (!this.formInline.broadcast_date)
        this.formInline.broadcast_date = this.datePickerDefaultValue;
      this._initState(this.formInline);
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
@import "./style.less";
.alert-style {
  margin-bottom: 9px;
  & /deep/ .el-alert--warning {
    color: #60c3f7;
    background: rgba(96, 195, 247, 0.1);
    font-size: 13px;
  }
}
</style>